export const badgeColors: Record<string, string> = {
  kotak_sheets: "#FF5733",        // Red-orange
  ppt: "#33FF57",                 // Green
  mf_recommendation: "#3357FF",   // Blue
  amfii_data: "#FF33A1",          // Pink
  mf_fact_sheet: "#FFD700",       // Gold
  nism_books: "#9400D3",          // Dark Violet
};


