import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaTrash } from "react-icons/fa";

const Settings: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [newKotakFile, setNewKotakFile] = useState<string>();
  const [existingKotakSheets, setExistingKotakSheets] = useState<string[]>([]);

  const baseurl = "https://jrlchatfi.atxlabs.ai/copilot/api/";
  const token = localStorage.getItem("access_token");

  const clearMessages = () => {
    setTimeout(() => {
      setMessage("");
      setError("");
    }, 2500);
  };

  const fetchUserDetails = () => {
    axios
      .get(baseurl + "user-details/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const userData = response.data;
        setUsername(userData.username);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to fetch user details.");
        clearMessages();
      });


  }

  const fetchExistingKotakSheets = () => {
    axios
      .get(baseurl + "fetch-existing-sheets/", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response.data;
        setExistingKotakSheets(data.data);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to fetch existing kotak sheets.");
        clearMessages();
      });


  }

  useEffect(() => {
    fetchUserDetails()
    fetchExistingKotakSheets()
  }, []);

  const handleUpdatePassword = () => {
    setError("");
    setMessage("");

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      clearMessages();
      return;
    }

    axios
      .post(
        baseurl + "update-password/",
        { password, confirm_password: confirmPassword },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setMessage("Password updated successfully.");
        setPassword("");
        setConfirmPassword("");
        clearMessages();
      })
      .catch((err) => {
        console.error(err);
        setError(
          err.response?.data?.non_field_errors || "Failed to update password."
        );
        clearMessages();
      });
  };


  const handleDeleteKotakSheet = (name: string) => {
    if (window.confirm("Are you sure you want to delete " + name)) {
      axios
        .delete(baseurl + "delete-existing-sheet/", {
          data: {
            "sheet_name": name
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((_) => {
          setMessage("Sheet deleted successfully.");
          clearMessages();
          fetchExistingKotakSheets()
        })
        .catch((err) => {
          console.error(err);
          setError(
            err.response?.data?.error || "Failed to delete the sheet."
          );
          clearMessages();
          fetchExistingKotakSheets()

        });
    }
  }

  const handleFileChange = (e: any) => {
    setNewKotakFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!newKotakFile) {
      setError("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("sheet", newKotakFile);

    axios
      .post(`${baseurl}upload-sheet/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMessage("File uploaded successfully.");
        setNewKotakFile(undefined);
        setError("");
        fetchExistingKotakSheets();
      })
      .catch((err) => {
        console.error(err);
        setError(
          err.response?.data?.error || "Failed to upload the file."
        );
        fetchExistingKotakSheets();
      });
  };

  return (
    <div className="w-full h-full min-h-screen bg-Grey text-white overflow-auto no-scrollbar">

      <div className="p-8 mt-6">
        <h2 className="text-4xl font-bold">Settings</h2>
      </div>


      <div className="flex border-b border-customLightGrey px-8 mb-10">
        <button className="px-5 py-3 font-semibold text-white border-b-2 border-customGreen">
          Account
        </button>
      </div>


      <div className="px-8 pb-12">
        <h3 className="text-2xl font-semibold mb-8">Your Profile</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="flex items-center">
            <label className="block text-lg font-semibold mb-2 w-1/3">
              Username
            </label>
            <input
              type="text"
              value={username}
              readOnly
              disabled
              className="w-2/3 px-4 py-3 text-lg rounded-lg bg-customAlmostBlack text-white cursor-default"
            />
          </div>
        </div>
      </div>


      <hr className="border-t border-customLightGrey mx-8 mb-12" />
      <div className="px-8 pb-12 flex flex-col">
        {existingKotakSheets.map(sheet => {
          return <div className="flex items-center justify-between bg-gray-700 m-1 p-4 max-w-64 rounded-lg" >
            <p>{sheet}</p>
            <FaTrash onClick={() => { handleDeleteKotakSheet(sheet) }} />
          </div>
        })}
        <h2>Upload Kotak Sheet</h2>
        <input type="file" accept=".xlsx" onChange={handleFileChange} />
        <button onClick={handleUpload} className="bg-customLightGreen max-w-64 rounded-lg bg-green-500 p-2 mt-5">Upload</button>
      </div>
      <hr className="border-t border-customLightGrey mx-8 mb-12" />

      <div className="px-8 pb-12">
        <h3 className="text-2xl font-semibold mb-8">Change Password</h3>
        {message && (
          <div className="bg-green-500 text-white px-3 py-2 rounded-lg mb-6">
            {message}
          </div>
        )}
        {error && (
          <div className="bg-white text-red-500 text-base px-3 py-2 rounded-lg mb-6">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          <div className="flex items-center">
            <label className="block text-lg font-semibold mb-2 w-1/3">
              New Password
            </label>
            <input
              type="password"
              placeholder="Enter new password"
              className="w-2/3 px-4 py-3 text-lg rounded-lg bg-customAlmostBlack border border-customLightGrey text-white"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-6">
          <div className="flex items-center">
            <label className="block text-lg font-semibold mb-2 w-1/3">
              Confirm Password
            </label>
            <input
              type="password"
              placeholder="Confirm new password"
              className="w-2/3 px-4 py-3 text-lg rounded-lg bg-customAlmostBlack border border-customLightGrey text-white"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        </div>

        <button
          onClick={handleUpdatePassword}
          className="px-6 py-3 mt-10 text-lg font-semibold bg-customGreen rounded-lg text-white hover:bg-green-500 transition"
        >
          Update Password
        </button>
      </div>
    </div>
  );
};

export default Settings;
