import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Message, Room } from '../interfaces/Interfaces';
import { useUser } from '../providers/UserProvider';
import ChatBubble from './ChatBubble';
import { badgeColors } from '../constants';
import { FaInfoCircle } from 'react-icons/fa';
import { formatUnderscoredData } from '../utils';

export interface ChatInterfaceProps {
  room: Room;
  currentApiChatInterface: string;
  header?: string;
  refreshRoomData: Function;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({ currentApiChatInterface, header, room, refreshRoomData }) => {

  const [sendBlock, setSendBlock] = useState<boolean>(false);
  const [isBotTyping, setIsBotTyping] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [hovered, setHovered] = useState<string | null>(null);
  const [currentDisplayedMetadata, setCurrentDisplayedMetadata] = useState<string[]>([]);
  const chatWindowRef = useRef<HTMLDivElement>(null);


  const { user } = useUser()

  const fetchMessages = () => {
    axios
      .get(`${currentApiChatInterface}`, { params: { roomid: room.id } })
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => console.error('Error fetching messages:', error));
  };


  useEffect(() => {
    fetchMessages()
  }, [user, room]);

  useEffect(() => {
    const uniqueMetadata = new Set<string>();

    room.current_metadata.forEach((meta: any) => {
      if (meta.extra && typeof meta.extra === 'object') {
        Object.entries(meta.extra).forEach(([key, value]) => {
          const formedLabel = `${formatUnderscoredData(key)}: ${value}`;
          uniqueMetadata.add(formedLabel); // Automatically handles duplicates
        });
      }
    });

    setCurrentDisplayedMetadata(Array.from(uniqueMetadata));
  }, [room]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages, isBotTyping]);

  const handleSendMessage = () => {

    setSendBlock(true);
    setIsBotTyping(true);

    axios
      .post(
        `${currentApiChatInterface}`,
        { user_id: user?.user_id, content: newMessage, roomid: room.id },

      )
      .then((_) => {
        setNewMessage('');
        setIsBotTyping(false);
        setSendBlock(false);
        fetchMessages();
        refreshRoomData(room.id);
      })
      .catch((error) => {
        console.error('Error sending message:', error);
        setNewMessage('');
        setSendBlock(false);
      });
  };

  const isBotMessage = (userid: string) => {
    return userid.toLowerCase() === "3";
  };


  return (
    <div className="h-screen max-w-6xl p-8 flex flex-col space-y-6 mx-auto">
      {header && <h1 className="text-5xl font-bold mb-6 text-white text-center">
        {header}
      </h1>
      }

      <div
        ref={chatWindowRef}
        className="flex flex-col flex-grow rounded-lg bg-customAlmostBlack p-4 overflow-scroll custom-scrollbar border-2 border-customDarkGrey"
      >
        <div className="flex-1">
        </div>
        {messages.map((message) => (
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex gap-2 flex-wrap relative">
                {message.datasources.map((datasource, index) => {
                  const metadata = message.metadata.find((meta: any) => meta.source === datasource);


                  return (
                    <div
                      key={index}
                      className="relative"
                      onMouseEnter={() => setHovered(datasource)}
                      onMouseLeave={() => setHovered(null)}
                    >
                      <span
                        className="px-3 py-1 mt-5 ml-10 rounded-full text-white font-semibold text-sm flex justify-between items-center gap-2"
                        style={{ backgroundColor: badgeColors[datasource] || "#cccccc" }}
                      >
                        {formatUnderscoredData(datasource)}
                        <FaInfoCircle />
                      </span>
                      {(hovered === datasource && metadata) && (
                        <div
                          className="absolute top-full left-0 -mt-2 px-4 py-2 rounded bg-gray-700 text-white text-xs shadow-lg z-10 overflow-auto"
                          style={{ minWidth: "200px" }}
                          onMouseEnter={() => setHovered(datasource)}
                          onMouseLeave={() => setHovered(null)}
                        >
                          {Object.keys(metadata.extra).map((key) => (
                            <p key={key}>{`${formatUnderscoredData(key)}: ${metadata.extra[key]}\n`}</p>
                          ))}
                        </div>
                      )}
                    </div>
                  );

                })}
              </div>
            </div>
            <div
              key={message.id}
              className={`inline-flex items-center rounded-3xl shadow-md text-white py-2 ${isBotMessage(message.user.toString())
                ? 'ml-0 text-gray-700 text-left mb-24'
                : 'ml-auto px-4 text-gray-800 bg-customLightGrey mb-4'
                }`}
            >
              {isBotMessage(message.user.toString()) &&
                <img src="https://atxlabs.ai/wp-content/uploads/2024/04/cropped-ATXLabs_Logogram_Circle_Filled_CoG-192x192.png" className='object-cover h-8 mr-2 mb-auto' />
              }
              <span className="">
                <ChatBubble
                  content={message.content}
                />
              </span>
            </div>
          </div>
        ))}

        {isBotTyping && (
          <div className="mb-2 p-3 bg-customLightGrey rounded-lg shadow-md w-fit max-w-xs">
            <strong className="text-gray-200">ChatFi:</strong>
            <span className="text-customGreen ml-1 italic">Typing...</span>
          </div>
        )}

        {messages.length === 0 && !isBotTyping && <div className="flex justify-center h-full items-center"><h1 className="text-gray-100 text-xl md:text-4xl">What can I help you with?</h1></div>}
      </div>

      <div className="">
        <div className="flex flex-wrap max-h-12 overflow-auto mb-2">
          {currentDisplayedMetadata.map((entry) => {
            return <span
              className="border-solid border px-1 mr-1 mb-1 border-gray-500 rounded-md text-white text-sm"
            >
              {entry}
            </span>
          })}
        </div>
        <div className="flex items-center space-x-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Type a message..."
            className={`w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent bg-customLightGrey border-gray-600 placeholder-gray-400 text-white shadow-lg shadow-customAlmostBlack 
          ${sendBlock ? "bg-gray-500" : ""}`}
            disabled={sendBlock}
          />

          <button
            onClick={handleSendMessage}
            className={`px-4 py-2 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 shadow-lg shadow-customAlmostBlack ${sendBlock
              ? 'bg-[#ceffbb] '
              : 'bg-customGreen hover:bg-customLightGreen'
              }`}
            disabled={sendBlock}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
