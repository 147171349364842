const formatUnderscoredData = (datasource: string): string => {
  return datasource
    .replaceAll("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, char => char.toUpperCase());
};


function formatDateTimeSmart(datetime: string): string {
  const date = new Date(datetime);
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });
}

export { formatDateTimeSmart, formatUnderscoredData }
